import React, { useEffect, useState } from "react";
import "./OurProduct.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

function OurProducts() {
  let [products, setproducts] = useState([]);
  useEffect(() => {
    axios
      .get("https://agree-export-backend.onrender.com/agree/product/getAll")
      .then((res) => {
        console.log(res.data.data);
        setproducts(res.data.data.slice(0, 4));
      });
  }, []);
  return (
    <div className="OurProducts-container">
      <div className="text-center text-[#218B1C] pt-10 pb-5">
        <span className="text-4xl">Our Products</span>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-44 left-8"/>
        </div>
      </div>
      <div className="w-full grid justify-center">
        <div class="OurProducts-list">
          {products.map((product, index) => {
            return (
              <div id={index} class="OurProducts">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`/product/${product._id}`}
                >
                <LazyLoadImage
                  className="ourproductimg object-cover shadow-2xl"
                  placeholder={<Loading />}
                  src={product.bannerImage}
                  alt="OurProduct Image"
                />
                <div class="OurProducts-info shadow-2xl">
                  <span><b>{product.name.charAt(0).toUpperCase() + product.name.slice(1)}</b></span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <Link
          to="/product/agriculture"
          className="h-8 w-28 text-xl justify-self-end pt-0.5 bg-[#218B1C] text-center text-white"
        >
          <span onClick={() => window.scrollTo(0, 0)}>
            More{" "}
            <svg
              fill="#ffffff"
              height="20px"
              width="16px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
              xml="preserve"
              stroke="#ffffff"
              className="relative bottom-[22px] left-[50px]"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  id="XMLID_27_"
                  d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                ></path>{" "}
              </g>
            </svg>{" "}
          </span>
        </Link>
      </div>
    </div>
  );
}

export default OurProducts;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setdropdown] = useState(false);
  

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const ref = useRef();

  useEffect(() => {
    document.addEventListener("mouseout", (event)=>{
      if (!ref?.current?.contains(event.target)) {
        setdropdown(false);
      }
    });

    document.body.addEventListener("click", (event)=>{
      if (!ref?.current?.contains(event.target)) {
        setdropdown(false);
      }
    });
  }, [ref]);


  return (
    <div>
      <nav className="bg-white border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-0">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse ml-0"
          >
            <img
              height="100px"
              width="107.88px"
              src="./AE.png"
              className="h-120 md:h-28 lg:h-32 xl:h-32 pt-2"
              alt="aummex exim Logo"
            />
          </Link>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-[#218B1C] focus:outline-none focus:ring-2 dark:text-gray-400 hover:text-white dark:hover:bg-[#218B1C] dark:focus:ring-white"
            aria-controls="navbar-dropdown"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`w-full md:block md:w-auto ${
              isMenuOpen ? "block" : "hidden"
            }`}
            id="navbar-dropdown"
          >
            <ul class="flex flex-col md:dark:text-[black] font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <Link to="/">
                <li
                  class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  Home
                </li>
              </Link>
              <Link to="/AboutUs">
                <li
                  class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  About Us
                </li>
              </Link>
              <li>
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  onClick={(e) =>{
                     setdropdown(!dropdown)
                     e.stopPropagation()
                     }}
                  ref={ref}
                  class="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent md:p-0 md:w-auto"
                >
                  Products{" "}
                  <svg
                    class="w-2.5 h-2.5 ms-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  id="dropdownNavbar"
                  class={`xl:absolute xl:top-[75px] xl:z-50 md:absolute md:top-[75px] md:z-50 ${
                    dropdown ? "block" : "hidden"
                  } font-normal bg-white divide-y divide-gray-100 rounded-lg shadow xl:w-28 dark:bg-white dark:divide-gray-600`}
                >
                  <ul
                    class="text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li
                      className="hover:bg-[#218B1C] dark:hover:bg-[#218B1C]"
                      onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                        setdropdown(false)
                      }}
                      onMouseEnter={(e)=>{
                        e.stopPropagation()
                      }}
                    >
                      <Link
                        to="/product/spice"
                        class="block hover:bg-[#218B1C] px-4 py-2 text-black dark:hover:text-white"
                      >
                        Spice
                      </Link>
                    </li>
                    <li
                      className="hover:bg-[#218B1C] dark:hover:bg-[#218B1C]"
                      onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                        setdropdown(false)
                      }}
                      onMouseEnter={(e)=>{
                        e.stopPropagation()
                      }}
                    >
                      <Link
                        to="/product/agriculture"
                        class="block hover:bg-[#218B1C] px-4 py-2 text-black dark:hover:text-white"
                      >
                        Agriculture
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <Link to="/Gallery">
                <li
                  class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  Gallery
                </li>
              </Link>
              {/* <Link to="/Certificates">
                <li
                  class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  Certificates
                </li>
              </Link> */}
              <Link to="/ContactUs">
                <li
                  class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  Contact Us
                </li>
              </Link>
              {/* <li>
          <Link to="/Gallery" class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent" onClick={()=>setIsMenuOpen(!isMenuOpen)}>Gallery</Link>
        </li>
        <li>
          <Link to="/Certificates" class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent" onClick={()=>setIsMenuOpen(!isMenuOpen)}>Certificates</Link>
        </li>
        <li>
          <Link to="/ContactUs" class="block py-2 px-3 rounded text-black bg-[#F3F8E9] hover:text-[#218B1C] rounded md:bg-transparent md:text-[#218B1C] md:p-0 md:dark:text-black dark:bg-[#F3F8E9] md:dark:bg-transparent" onClick={()=>setIsMenuOpen(!isMenuOpen)}>Contact Us</Link>
        </li> */}
            </ul>

          </div>
            <div id="google_translate_element"></div>
        </div>
      </nav>
    </div>
  );
}

export default Header;

import React from "react";

function Testonimals() {
  return (
    <div class="w-4/5 mx-auto md:px-6 bg-[#EAF2DB] xl:mb-24 md:w-4/5 ">
      <section class="mb-32 text-center">
        <h2 class="text-3xl font-bold pt-10 text-[#218B1C]">
          Customer Review
        </h2>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-44 pb-4 left-12"/>
        </div>
        <div className="grid justify-center">
          <p class="w-64 pb-8 md:w-96 lg:w-96 xl:w-full text-justify">
            Are you food lover? If your answer is yes,then in resturant for
            you.We serve foods for reasonable prices.it’s awesome,we know.Eating
            a variety of foods and
          </p>
        </div>
        <div class="grid gap-x-6 lg:grid-cols-3 lg:gap-x-10 xl:grid-cols-3 xl:gap-x-12 xl:pl-10 xl:pr-10">
          <div class="mb-12 w-64 md:w-96 justify-self-center rounded-xl bg-white lg:w-[250px] lg:mt-16 xl:w-[350px] xl:h-3/4 xl:p-16 xl:mt-20">
            <figcaption class="flex justify-center pt-2 items-center">
              <img
                class="w-20 h-20 rounded-full"
                src="./review-1.jfif"
                alt="profile picture"
              />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div class="text-2xl pl-4 font-light text-black">
                  Rohan Patel
                </div>
                {/* <div class="text-sm pl-4 font-light text-gray-500 dark:text-gray-400">Robot fox</div> */}
              </div>
            </figcaption>
            <ul class="mb-0 flex justify-center pt-8 text-[yellow]">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
            </ul>
            <p class="mb-4 pt-4">
              Aummex Exim has truly set the bar high when it comes to quality
              and service in the spice export industry. I recently imported a
              variety of spices. The spices were incredibly fresh, aromatic, and
              arrived well-packaged.
            </p>
          </div>
          <div class="mb-12 w-64 md:w-96 md:mt-6 lg:w-[250px] lg:mb-24 justify-self-center rounded-xl bg-white xl:w-[350px] xl:h-3/4 xl:p-16">
            <figcaption class="flex justify-center pl-4 pt-2 items-center">
              <img
                class="w-20 h-20 rounded-full"
                src="./review-3.png"
                alt="profile picture"
              />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div class="text-2xl pl-4 font-light text-black">
                  Maria Sanchez
                </div>
                {/* <div class="text-sm pl-4 font-light text-gray-500 dark:text-gray-400">Robot fox</div> */}
              </div>
            </figcaption>
            <ul class="mb-0 flex justify-center pt-8 text-[yellow]">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
            </ul>
            <p class="mb-4 pt-4">
              Aummex Exime is a gem for anyone in the culinary business. I
              ordered a bulk shipment of cardamom (elaichi) and black pepper
              (kali mirch) for my spice shop in Winnepeg, Canada. The delivery
              was timely, and the spices were of exceptional quality.
            </p>
          </div>
          <div class="mb-12 w-64 md:w-96 md:mt-6 justify-self-center rounded-xl bg-white lg:w-[250px] lg:mt-16 xl:w-[350px] xl:h-3/4 xl:p-16 xl:mt-20">
            <figcaption class="flex justify-center pl-4 pt-2 items-center">
              <img
                class="w-20 h-20 rounded-full"
                src="./review-2.png"
                alt="profile picture"
              />
              <div class="space-y-0.5 font-medium dark:text-white text-left">
                <div class="text-2xl pl-4 font-light text-black">
                  Lars Johansson
                </div>
                {/* <div class="text-sm pl-4 font-light text-gray-500 dark:text-gray-400">Robot fox</div> */}
              </div>
            </figcaption>
            <ul class="mb-0 flex justify-center pt-8 text-[yellow]">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  class="w-8 text-[#FEA724] text-warning"
                >
                  <path
                    fill="currentColor"
                    d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                  />
                </svg>
              </li>
            </ul>
            <p class="mb-4 pt-4">
              As a chef based in london, I have been sourcing spices from Aummex
              Exim for over a year now. Their fenugreek (methi) and coriander
              (dhaniya) are top-notch.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testonimals;

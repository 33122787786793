import React, { useEffect, useState } from "react";
import ChooseUs from "../WhyYouChooseUs/ChooseUs";
import OurProducts from "../OurProducts/OurProducts";
import Testonimals from "../Testonimals/Testonimals";
import HomeAbout from "../HomeAbout/HomeAbout";
import Demo from "../../demo";
import Blogs from "../Blogs/Blogs";
import AutomatedSlider from "../AutomatedSlider/AutomatedSlider";
import axios from "axios";
import { Helmet } from "react-helmet";

// const images = [
//       "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
//       "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
//       "https://images.pexels.com/photos/54455/cook-food-kitchen-eat-54455.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
// ];
function Home() {
  let [imgindex, setimgindex] = useState(0);
  let [MembershipImages, setMembershipImages] = useState([]);
  useEffect(() => {
    axios
      .get("https://agree-export-backend.onrender.com/agree/membership/getAll")
      .then((res) => {
        // console.log(res.data.data,"dfkjnvkjsnv");
        setMembershipImages(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (imgindex == MembershipImages?.length - 1) {
        setimgindex(0);
      } else {
        setimgindex(imgindex + 1);
      }
    }, 4000);
    return () => {};
  }, [imgindex]);
  return (
    <div id="top">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/" />
      </Helmet>
      <div
        id="controls-carousel"
        class="relative w-full"
        data-carousel="static"
      >
        <div
          class="relative h-56 overflow-hidden rounded-lg md:h-96"
          style={{ borderBottomRightRadius: "200px" }}
        >
          {MembershipImages?.map((MembershipImage, index) => {
            return (
              <div
                class={
                  imgindex == index
                    ? "duration-700 ease-in-out"
                    : "hidden duration-700 ease-in-out"
                }
                data-carousel-item
              >
                <img
                  src={MembershipImage?.image}
                  class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  alt="..."
                />
              </div>
            );
          })}
        </div>
        {/* <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={()=>{
              if(imgindex == MembershipImages?.length-1){
                setimgindex(0)
              }else{
              setimgindex(imgindex-1)
              }
              }
              } data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={()=>{
              if(imgindex == MembershipImages?.length-1){
                setimgindex(0)
              }else{
              setimgindex(imgindex+1)
              }
              }
              } data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button> */}
      </div>

      <HomeAbout />

      <OurProducts />

      <ChooseUs />

      <AutomatedSlider />

      <div>
        <div className="text-center text-[#218B1C] xl:p-10 xl:pb-5">
          <span className="text-4xl">Certificates</span>
          <div className="grid justify-evenly">
            <img src="./arrow.svg" className="relative w-44 left-4" />
          </div>
        </div>
        <Demo />
      </div>
      <Blogs />
      <Testonimals />
    </div>
  );
}

export default Home;

import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import About from "./componants/AboutUs/About";
import Home from "./componants/Home/Home";
import Contact from "./componants/Contact/Contact";
import Certificates from "./componants/Certificates/Certificates";
import Spice from "./componants/SpiceProducts/Spice";
import Agriculture from "./componants/AgricultureProducts/Agriculture";
import NotFound from "./componants/NotFound";
import Header from "./componants/Header/Header";
import Footer from "./componants/Footer/Footer";
import Demo from "./demo";
import Product from "./componants/Product/Product";
import MainBlog from "./componants/mainblog/MainBlog";
import React from "react";
import Gallery from "./componants/Gallery/Gallery";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Link class="top-link hide" to="/contactus" id="js-top">
          <svg
            fill="#ffffff"
            height="50px"
            width="50px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="-31.06 -31.06 269.21 269.21"
            space="preserve"
            stroke="#ffffff"
            stroke-width="20.7093"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path d="M114.133,205.962H92.914c-4.112,0-7.458-3.345-7.458-7.457v-5.586c0-4.112,3.345-7.458,7.458-7.458h21.219 c4.112,0,7.457,3.345,7.457,7.458v0.793h28.303c16.213,0,29.403-13.19,29.403-29.403v-12.757 c-5.878-0.958-10.378-6.072-10.378-12.216v-41.31c0-6.039,4.348-11.082,10.077-12.164v-5.257c0-41.616-33.856-75.472-75.472-75.472 c-41.616,0-75.473,33.856-75.473,75.472v5.249c5.753,1.063,10.124,6.117,10.124,12.172v41.31c0,6.825-5.553,12.378-12.378,12.378 c-6.675,0-12.133-5.311-12.37-11.929C5.227,135.958,0,127.817,0,118.679c0-9.138,5.227-17.279,13.427-21.104 c0.216-6.031,4.767-10.976,10.624-11.807v-5.166c0-43.821,35.651-79.472,79.472-79.472c43.821,0,79.473,35.651,79.473,79.472v5.159 c5.88,0.811,10.454,5.766,10.671,11.813c8.2,3.825,13.427,11.967,13.427,21.104s-5.227,17.278-13.427,21.104 c-0.213,5.943-4.636,10.833-10.37,11.768v12.757c0,18.418-14.984,33.403-33.403,33.403H121.59v0.793 C121.59,202.616,118.245,205.962,114.133,205.962z M92.914,189.461c-1.906,0-3.458,1.551-3.458,3.458v5.586 c0,1.906,1.551,3.457,3.458,3.457h21.219c1.906,0,3.457-1.551,3.457-3.457v-5.586c0-1.906-1.551-3.458-3.457-3.458H92.914z M181.296,89.646c-4.619,0-8.378,3.758-8.378,8.378v41.31c0,4.62,3.759,8.378,8.378,8.378s8.378-3.758,8.378-8.378v-41.31 C189.674,93.405,185.915,89.646,181.296,89.646z M25.797,89.646c-4.62,0-8.378,3.758-8.378,8.378v41.31 c0,4.62,3.758,8.378,8.378,8.378s8.378-3.758,8.378-8.378v-41.31C34.175,93.405,30.417,89.646,25.797,89.646z M13.419,102.098 C7.613,105.538,4,111.764,4,118.679c0,6.915,3.613,13.141,9.419,16.581V102.098z M193.674,102.098v33.163 c5.806-3.44,9.419-9.666,9.419-16.581S199.479,105.538,193.674,102.098z"></path>{" "}
            </g>
          </svg>
          {/* <span class="screen-reader-text">Back to top</span> */}
        </Link>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<About />}></Route>
          <Route path="/Gallery" element={<Gallery />}></Route>
          <Route path="/Certificates" element={<Certificates />}></Route>
          <Route path="/ContactUs" element={<Contact />}></Route>
          <Route path="/Blogs" element={<MainBlog />}></Route>
          <Route path="/product/spice" element={<Spice />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/product/agriculture" element={<Agriculture />} />
          <Route path="/*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

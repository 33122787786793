import React, { Suspense, useEffect, useState } from "react";
import "./Gallery.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from "axios";
import { Helmet } from "react-helmet";
import Loading from "../Loading/Loading";
import LazyLoad from "react-lazy-load";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loader from "../Loading/Loader";
const Image = React.lazy(()=>import("../Shared/Image"));

function Gallery() {
  let [GalleryImages,setGallaryImages] = useState(null)
  useEffect(()=>{
    axios.get("https://agree-export-backend.onrender.com/agree/gallery/getAll")
    .then((res)=>{
      console.log(res.data.data);
      setGallaryImages(res.data.data)
    })
  },[])
  return (
    <>
    {GalleryImages != null ? <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/Gallery" />
      </Helmet>
      <div class="example">
        <img style={{"marginTop":"-39px"}} src="https://image.slidesdocs.com/responsive-images/background/inspection-of-goods-3d-rendered-hand-checking-cargo-for-import-export-business-powerpoint-background_428047d7f3__960_540.jpg" />
        <p>Gallery</p>
      </div>
      <main class="grid justify-center">
        <h2 class="text-5xl text-[#218B1C] mt-24 text-center">
          Gallery
        </h2>
        <div className="grid justify-evenly">
        <img src="../arrow.svg" className="relative w-32 pt-1 md:w-32 lg:w-32 xl:w-36 left-4"/>
        </div>

        <div class="grid grid-cols-2 gap-x-4 gap-y-4 pt-8 pb-12 w-[340px] justify-center xl:w-[1340px] lg:w-[800px] md:w-[600px] md:grid-cols-3 md:gap-y-6 md:gap-x-2 lg:gap-y-8 lg:pb-10 lg:grid-cols-4 xl:grid-cols-4 xl:gap-y-28 xl:pl-20 xl:pr-20 xl:pb-36">
          {GalleryImages.map((GalleryImage,index) => {
            return (
              <div class="h-56 w-44 w-full xl:w-72 sm:w-48 lg:w-64 md:w-48 md:h-44">
              {/* <Suspense fallback={<Loading/>}>
                <Image imagesrc={GalleryImage.image} index={index}/>
              </Suspense> */}
              <LazyLoadImage
              src={GalleryImage.image}
              class="object-cover h-full w-full rounded-lg xl:w-80 xl:h-64 md:w-48 md:h-44 lg:w-44 lg:h-44"
              placeholder={<Loading/>}
              />
              </div>
            );
          })}
        </div>
      </main>
    </div> : <Loader/>}
    </>
  );
}

export default Gallery;

import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import "./spinner.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    company: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const resetFormFields = () => {
    setFormData({
      firstName: "",
      company: "",
      email: "",
      phoneNumber: "",
      address: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const form = useRef();

  const ToastSuccess = () => {
    toast.success("Email sent.");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    emailjs
      .sendForm(
        "service_pe2yu8h",
        "template_14ox53k",
        form.current,
        "YDSJ7ZSlV8KHNZ5X0"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          resetFormFields();
          toast.success("Send Successfully");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/ContactUs" />
      </Helmet>
      <div class="example">
        <img style={{"marginTop":"-39px"}} src="https://www.digitalmarketingindore.com/wp-content/uploads/2023/09/40-min.webp" />
        <p>Contact Us</p>
      </div>
      <section class="p-10 md:p-20 contact" id="contact">
        <div class="container  px-45 py-101 mx-auto">
          <div class="heading text-center">
            <h2 class="text-3xl font-bold mb-4">
              Get In <span class="text-[#218B1C]"> Touch </span>
            </h2>
            <p class="text-gray-500">
            Need to connect? We're happy to hear from you! Whether you have a question, feedback, or just want to chat, reach out by phone, email, or social media. Our friendly team is here to help.
            </p>
          </div>
          <div class="flex flex-col items-center justify-center w-full md:flex-row md:justify-start md:items-start">
            <div class="w-full md:w-1/2 mb-8 md:mb-0">
              <div class="content lg:m-4">
              <div class="info text-left mb-4">
                <h3 class="text-xl font-semibold  pb-2 pl-3" style={{"font-size":"28px"}}>Contact Us</h3>
              </div>
                <div class="info flex items-center mb-4">
                  <div class="icon mr-4">
                    <i class="fas fa-mobile-alt text-2xl text-green-500"></i>
                  </div>
                  <div class="text">
                    <h4 class="text-base font-medium mb-1">
                      PHONE :
                      <br />
                      <span>+91 9824454595</span>
                    </h4>
                  </div>
                </div>
                <div class="info flex items-center mb-4">
                  <div class="icon mr-4">
                    <i class="far fa-envelope text-2xl text-green-500"></i>
                  </div>
                  <div class="text">
                    <h4 class="text-base font-medium mb-1">
                      EMAIL :
                      <br />
                      <span>info@aummexeximllp.com</span>
                    </h4>
                  </div>
                </div>
                <div class="info flex items-center mb-4">
                  <div class="icon mr-4">
                    <i class="fas fa-map-marker-alt text-2xl text-green-500"></i>
                  </div>
                  <div class="text">
                    <h4 class="text-base font-medium mb-[-10px]">
                      ADDRESS :<br />
                      <span className="w-44">
                        414, 4th Floor, Kedar Bussiness Center, near Dabholi
                        char rasta, Dabholi, Surat. 395004
                      </span>
                    </h4>
                  </div>
                </div>
                <div class="info flex items-center mb-64">
                  <div class="icon mr-4">
                  <a href="https://api.whatsapp.com/send/?phone=919824454595">
                  <i style={{"color": "#05444c"}} class="fab fa-whatsapp "></i>
                  </a>
                  </div>
                  <div class="text">
                    <a href="https://api.whatsapp.com/send/?phone=919824454595"><h4 class="text-base font-medium mb-1 mt-6">
                      WHATSAPP<br />
                      <span>
                        
                      </span>
                    </h4></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 md:mr-10 xl:mr-44">
              <form
                class="w-full max-w-lg mx-auto"
                ref={form}
                onSubmit={sendEmail}
              >
                <div class="flex flex-wrap mb-4">
                  <div class="w-full md:w-1/2">
                    <input
                      type="text"
                      name="firstName"
                      id="first-name"
                      class="form-control md:w-[160px] lg:w-[170px] xl:w-[240px] h-0.5 block w-full px-4 py-3 leading-normal bg-gray-800 border border-gray-300 rounded-md appearance-none  outline-[#8ad188]"
                      placeholder="Full Name"
                      onChange={(e) => handleChange(e)}
                      value={formData.firstName}
                      required
                    />
                  </div>
                  <div class="w-full md:w-1/2">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      class="form-control mt-3 xl:mt-0 md:mt-0 md:w-[160px] lg:w-[170px] xl:w-[240px] h-0.5 block w-full px-4 py-3 leading-normal bg-white border border-gray-300 rounded-md appearance-none  outline-[#8ad188]"
                      placeholder="Company"
                      onChange={(e) => handleChange(e)}
                      value={formData.company}
                      required
                    />
                  </div>
                  <div class="w-full md:w-1/2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control mt-3 md:w-[160px] lg:w-[170px] xl:w-[240px] h-0.5 block w-full px-4 py-3 leading-normal bg-white border border-gray-300 rounded-md appearance-none  outline-[#8ad188]"
                      placeholder="Email"
                      onChange={(e) => handleChange(e)}
                      value={formData.email}
                      required
                    />
                  </div>
                  <div class="w-full md:w-1/2">
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      class="form-control mt-3 md:w-[160px] lg:w-[170px] xl:w-[240px] h-0.5 block w-full px-4 py-3 leading-normal bg-white border border-gray-300 rounded-md appearance-none  outline-[#8ad188]"
                      placeholder="Phone Number"
                      onChange={(e) => handleChange(e)}
                      value={formData.phoneNumber}
                      required
                    />
                  </div>
                  <div class="w-full">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      class="form-control md:w-[340px] mt-3 lg:w-[360px] xl:w-[495px] h-0.5 block w-full px-4 py-3 leading-normal bg-white border border-gray-300 rounded-md appearance-none  outline-[#8ad188]"
                      placeholder="address"
                      onChange={(e) => handleChange(e)}
                      value={formData.address}
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    name="message"
                    id="message"
                    class="form-control md:w-[340px] lg:w-[360px] xl:w-[495px] w-full px-4 py-3 bg-green-500 rounded-md outline-[#8ad188] "
                    rows="5"
                    id="comment"
                    placeholder="Message"
                    onChange={(e) => handleChange(e)}
                    value={formData.message}
                    required
                  ></textarea>   
                </div>
                <br />
                <button
                  class="btn btn-block bg-[#218B1C] text-white font-semibold  py-2 px-4 rounded "
                  type="submit"
                >
                 {loading ? "loading..." : "Send Now!"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div class="p-10 md:p-20 xl:pt-10 map_sec flex justify-center">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="map_inner bg-white text-center">
                <h1 class="text-[50px] text-black">Where to Find Us</h1>
                <div class="map_bind mt-10 md:mt-16 rounded-lg overflow-hidden">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.0500708961085!2d72.82234987477447!3d21.22986298084201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f353b70275f%3A0xef30897c9bca0e0b!2sKBC%20-%20KEDAR%20BUSINESS%20CENTER!5e0!3m2!1sen!2sin!4v1716827436413!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameborder="0"
                    styles="border: 0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;

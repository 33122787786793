import React from "react";
import "./footer.css";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
// import categoryData from '../data/category.data';
// import axios from "axios";

function Footer() {
  return (
    <footer className="bg-black items-center text-white dark:bg-black lg:text-centre w-full pin-b">
      {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
      <div className="mx-6 py-5 text-center md:text-left">
        <div className="footer-container mr-36">
          {/* <!-- TW Elements section --> */}

          {/* <!-- Products section --> */}
          <div className="compny-logo">
            <h6 class="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <span class="me-3 [&>svg]:h-4 [&>svg]:w-4">
                <img
                  height="200px"
                  width="200px"
                  src="/img.png"
                  style={{ position: "relative", left: "90px" }}
                />
              </span>
            </h6>
            <p class="w-56 ml-[100px]">
              Aummex Exim is a distinguished enterprise known for its expertise
              in importing and exporting goods. The company is committed to
              excellence and orchestrates seamless international trade solutions
              across various industries.
            </p>
          </div>

          <div className="footer-links lg:w-44 md:w-24">
            <h2 className="mb-3 text-xl justify-center md:w-36 font-semibold md:justify-start">
              Quick Links
            </h2>
            <p className="mb-3">
              <Link to="/">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  Home
                </span>
              </Link>
            </p>
            <p className="mb-3">
              <Link to="/AboutUs">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  About Us
                </span>
              </Link>
            </p>
            <p className="mb-3">
              <Link to="/product/spice">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  Products
                </span>
              </Link>
            </p>
            <p className="mb-3">
              <Link to="/Gallery">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  Gallery
                </span>
              </Link>
            </p>
            <p className="mb-3">
              <Link to="/certificates">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  Safety & Quality
                </span>
              </Link>
            </p>
            <p className="mb-3">
              <Link to="/Blogs">
                <span
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-white"
                >
                  Blogs
                </span>
              </Link>
            </p>
          </div>

          <div className="compny-info">
            <h6 className="mb-3 flex text-xl justify-center font-semibold md:justify-start">
              About Us
            </h6>
            <p className="mb-3 flex items-center justify-center md:justify-start">
              <svg
                class="w-6 h-6 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                  clip-rule="evenodd"
                />
              </svg>

              <span className="sm:ml-0 text-sm ml-0 xl:ml-4 md:ml-4 lg:ml-4 w-56 md:text-base">
                {" "}
                <b>Corporate Office Address :</b> 414, 4th Floor, Kedar
                Bussiness Center, near Dabholi char rasta, Dabholi, Surat.
                395004
              </span>
            </p>
            <p className="mb-3 flex items-center justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5 md:h-6 md:w-6"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              <span className="text-sm ml-1 md:text-base">
                info@aummexeximllp.com
              </span>
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5 md:h-6 md:w-6"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-sm md:text-base">+91 9824454595</span>
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <a href="https://api.whatsapp.com/send/?phone=919824454595">
                <svg
                  class="w-6 h-6 text-white mr-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                  />
                </svg>
              </a>
              <a href="https://api.whatsapp.com/send/?phone=919824454595">
                <span className="text-sm md:text-base">WhatsApp</span>
              </a>
            </p>
          </div>
        </div>
        <div class="main-social-links pr-4 pt-4">
          <div class="social-links pr-40 md:mx-16 lg:mx-16 xl:mx-[-30px]">
            <a href="#!" class="me-6 [&>svg]:h-4 [&>svg]:w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 320 512"
              >
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>
            </a>
            <a href="#!" class="me-6 [&>svg]:h-4 [&>svg]:w-4 ">
              <svg
                height="200px"
                width="200px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 461.001 461.001"
                space="preserve"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <path
                      style={{"fill":"#ffffff"}}
                      d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                    ></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </a>
            <a href="#!" class="me-6 [&>svg]:h-4 [&>svg]:w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>
            <a href="#!" class="me-6 [&>svg]:h-4 [&>svg]:w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 448 512"
              >
                <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
              </svg>
            </a>
          </div>
          <div className="copyright md:mr-10 xl:pl-36 text-[12px] xl:text-[17px]">
            Copyright @AummexExim. All Rights Reserved.
          </div>
        </div>
        <span className="grid justify-center pt-2 text-[12px] xl:text-[17px]">
          <a href="https://www.thetechizm.com/" target="_blank">
            Design and developed by Techizm
          </a>
        </span>
      </div>

      {/* <!--Copyright section--> */}
    </footer>
  );
}

export default Footer;

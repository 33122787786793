import React, { Suspense, useEffect, useState } from "react";
import "./Certificates.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import Loader from "../Loading/Loader";
const Image = React.lazy(() => import("../Shared/Image"));

function Certificates() {
  const Navigate = useNavigate();
  let [CertificatesImages, setCertificatesImages] = useState(null);
  useEffect(() => {
    axios
      .get("https://agree-export-backend.onrender.com/agree/certificate/getAll")
      .then((res) => {
        console.log(res.data.data);
        setCertificatesImages(res.data.data);
      });
  }, []);
  return (
    <>
    {CertificatesImages != null ? <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Certificates - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/certificates" />
      </Helmet>
      <div class="example ">
        <img
          style={{ marginTop: "-39px" }}
          src="https://static.vecteezy.com/system/resources/previews/037/246/494/non_2x/ai-generated-graduation-advertisment-background-with-copy-space-free-photo.jpg"
        />
        <p>Certificates</p>
      </div>
      <main class="grid justify-center mb-16">
        <h2 class="text-3xl pt-10 lg:text-5xl text-[#218B1C] text-center ">
          Certificates
        </h2>
        <div className="grid justify-evenly">
        <img src="../arrow.svg" className="relative w-36 md:w-32 lg:w-56 xl:w-56 left-4"/>
        </div>

        <p class="pt-8 pb-12 w-[340px] text-justify xl:w-[1375px] xl:pl-24 xl:pr-24 lg:w-[655px] md:w-[640px] lg:pl-0 lg:pr-0 lg:pb-10">
          At Aummex Exim, we pride ourselves on adhering to the highest
          standards of quality and compliance in the export industry. Our
          certifications are a testament to our unwavering commitment to
          excellence and our dedication to providing our clients with the best
          possible products and services. By choosing Aummex Exim, you are
          choosing a partner dedicated to delivering the best, every time.
        </p>

        <div class="grid grid-cols-2 gap-x-4 gap-y-6 pt-8 pb-12 w-[340px] justify-center xl:w-[1380px] lg:w-[685px] md:w-[660px] md:grid-cols-3 md:gap-y-8 md:gap-x-12 lg:gap-y-6 lg:pb-10 lg:gap-x-0 lg:grid-cols-4 xl:grid-cols-4 xl:gap-y-[30px] xl:pl-24 xl:pr-24 xl:pb-16">
          {CertificatesImages.map((CertificatesImage, index) => {
            return (
              <div class="h-56 w-44 w-full xl:w-72 xl:h-64 sm:w-48 lg:w-[150px] lg:h-[150px] md:w-44 md:h-44">
                <Suspense fallback={<Loading />}>
                  <a href={CertificatesImage.image} target="_blank">
                    <Image imagesrc={CertificatesImage.image} index={index} />
                  </a>
                </Suspense>
              </div>
            );
          })}
        </div>
      </main>
    </div> : <Loader/>}
    </>
  );
}

export default Certificates;

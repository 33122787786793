import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ProductLoading from "../Loading/ProductLoading";
import "./Product.css"
import Loader from "../Loading/Loader";
const Img = React.lazy(()=>import("../Shared/Image"));

function Product() {
  const { id } = useParams();
  let [product, setproduct] = useState(null);
  useEffect(() => {
    axios
      .get(`https://agree-export-backend.onrender.com/agree/product/get/${id}`)
      .then((res) => {
        // console.log(res.data.data);
        // res.data.data.images = JSON.parse(res.data.data.images);
        // console.log(res.data.data.images);
        setproduct(res.data.data);
      });
  }, []);

  return (
    <>
     {product == null ? <Loader/> : 
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product.name + " - Aummex exim"}</title>
        <link rel="canonical" href={`https://aummexeximllp.com/product/${id}`} />
      </Helmet>
      <div class="example">
        <img style={{"marginTop":"-39px"}} class="w-screen h-[400px]" src={product?.bannerImage} />
      </div>
      <main class="grid justify-center">
        <h2 class="text-3xl pt-10 lg:text-5xl text-center" id="producttitle">
          <b>{product?.name?.charAt(0)?.toUpperCase() + product?.name?.slice(1)}</b>
        </h2>

        <p class="pt-8 pb-12 w-[340px] text-justify xl:w-[1390px] xl:pl-24 xl:pr-24 lg:w-[655px] md:w-[640px] lg:pl-0 lg:pr-0 lg:pb-10">
          {product?.description}
        </p>

        <div class="grid grid-cols-2 gap-x-4 gap-y-6 pt-8 pb-12 w-[340px] justify-center xl:w-[1380px] lg:w-[685px] md:w-[660px] md:grid-cols-3 md:gap-y-8 md:gap-x-12 lg:gap-y-6 lg:pb-10 lg:gap-x-0 lg:grid-cols-4 xl:grid-cols-4 xl:gap-y-[35px] xl:gap-x-6 xl:pl-24 xl:pr-24 xl:pb-16">
          {product?.images?.map((img,index) => {
            return (
              <div class="h-56 w-44 w-full xl:w-72 xl:h-64 sm:w-48 lg:w-[150px] lg:h-[150px] md:w-44 md:h-44">
                <Suspense fallback={<ProductLoading/>}>
                <Img imagesrc={img} index={index}/>
                </Suspense>
              </div>
            );
          })}
        </div>
      </main>
    </div>}
    </>
  );
}

export default Product;

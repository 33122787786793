import React, { Suspense, useEffect, useState } from "react";
import "./Agriculture.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ProductLoading from "../Loading/ProductLoading";
import Loader from "../Loading/Loader";
const Productimage = React.lazy(() => import("../Shared/Productimage"));

function Agriculture() {
  let [agreeproducts, setagreeproducts] = useState(null);
  let [backupagreeproducts, setbackupagreeproducts] = useState([]);
  let [filtervalue, setfiltervalue] = useState("");
  // let [filterproduct,setfilterproduct] = useState([])
  useEffect(() => {
    axios
      .get("https://agree-export-backend.onrender.com/agree/product/getAllVeg")
      .then((res) => {
        console.log(res.data.data);
        setagreeproducts(res.data.data);
        setbackupagreeproducts(res.data.data);
      });
  }, []);

  useEffect(() => {
    if (filtervalue.length == 0) {
      setagreeproducts(backupagreeproducts);
    } else {
      setagreeproducts(
        backupagreeproducts.filter(
          (agreeproduct) => agreeproduct.name.toLowerCase().indexOf(filtervalue.toLowerCase()) >= 0
        )
      );
    }
  }, [filtervalue]);
  return (
    <>
    {agreeproducts != null ? <div className="mb-16">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Agriculture products - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/product/agriculture" />
      </Helmet>
      <div class="example sm:text-[20px]">
        <img
          style={{ marginTop: "-39px" }}
          className="object-cover"
          src="https://images4.alphacoders.com/902/thumb-1920-902486.jpg"
        />
        <p  className="text-[20px] w-[174px] xl:w-full xl:text-center md:w-full md:text-center lg:w-full lg:text-center">Agriculture Products</p>
      </div>
      <main class="main grid justify-center mb-36 lg:mb-24 md:mb-24 xl:mb-44">
        <div class="relative max-w-sm mx-auto mt-20">
          <input
            class=" w-[345px] xl:w-[385px] md:w-[385px] lg:w-[385px] py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            type="search"
            onChange={(e) => setfiltervalue(e.target.value)}
            placeholder="Search"
          />
          <button class="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700 bg-gray-100 border border-gray-300 rounded-r-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.795 13.408l5.204 5.204a1 1 0 01-1.414 1.414l-5.204-5.204a7.5 7.5 0 111.414-1.414zM8.5 14A5.5 5.5 0 103 8.5 5.506 5.506 0 008.5 14z"
              />
            </svg>
          </button>
        </div>
        <h2 class="text-3xl xl:text-5xl text-[#218B1C] mt-16 text-center">
          Agriculture
        </h2>
        <div className="grid justify-evenly">
        <img src="../arrow.svg" className="relative w-36 md:w-32 lg:w-36 xl:w-56 left-4"/>
        </div>

        <div class="grid grid-cols-2 gap-x-4 gap-y-16 pt-8 pb-12 w-[340px] justify-center xl:w-[1340px] lg:w-[685px] md:w-[660px] md:grid-cols-3 md:gap-y-20 md:gap-x-12 md:pl-10 md:pr-10 lg:gap-y-16 lg:pb-10 lg:gap-x-0 lg:grid-cols-4 xl:grid-cols-4 xl:gap-y-44 xl:pl-32 xl:pr-32 xl:pb-16">
        
          {agreeproducts.map((agreeproduct, index) => {
            return (
              <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`/product/${agreeproduct._id}`}
                >
              <div class="h-56 w-40 xl:w-80 sm:w-48 lg:w-[150px] lg:h-[150px] md:w-44 md:h-44 ">
                <Suspense fallback={<ProductLoading />}>
                  <Productimage
                    imagesrc={agreeproduct.bannerImage}
                    index={index}
                  />
                </Suspense>
                <div class="pt-2 bg-slate-100 shadow-2xl text-center text-1xl pb-2 text-center lg:text-2xl lg:pb-4 font-bold text-black-400 h-12 w-40 rounded-b-lg md:w-44 xl:w-64 lg:w-[150px]">
                  <Link to={`/product/${agreeproduct._id}`}>
                    <h3>{agreeproduct.name.charAt(0).toUpperCase() + agreeproduct.name.slice(1)}</h3>
                  </Link>
                </div>
              </div>
              </Link>
            );
          })}
        </div>
      </main>
    </div> : <Loader/>}
    </>
  );
}

export default Agriculture;

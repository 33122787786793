import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import Loading from "../Loading/Loading";
import Loader from '../Loading/Loader';

function MainBlog() {
  let [Blogs,setBlogs] = useState(null)
  let Navigate = useNavigate()
  useEffect(()=>{
    axios.get("https://agree-export-backend.onrender.com/agree/blog/getAll")
    .then((res)=>{ 
      setBlogs(res.data.data)
    })
  },[])
  return (
    <>
    {Blogs != null ? <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/Blogs" />
      </Helmet>
      <div class="example ">
        <img style={{"marginTop":"-39px"}} src="https://image.slidesdocs.com/responsive-images/background/inspection-of-goods-3d-rendered-hand-checking-cargo-for-import-export-business-powerpoint-background_428047d7f3__960_540.jpg" />
        <p>Blogs</p>
      </div>
      <div className='pt-10'>
        {/* <h2 class="text-3xl 2xl:text-5xl text-lime-400  mt-24 text-center pb-12">
          Blogs
        </h2> */}

        <div>
          {Blogs.map((blog) => {
            return (
              <div>
              <div>
                <LazyLoadImage
                  placeholder={<Loading/>}
                  class="object-cover h-44 w-64 md:w-80 md:h-64 lg:h-64 lg:w-80 xl:h-80 xl:w-96 mx-auto"
                  src={blog.image}
                  alt="Image Gallery"
                />
                </div>
                <div className='w-4/5 mx-auto'>
                <h1 className='text-3xl pt-10 pl-6'><b>{blog.name}</b></h1>
                <p className='pt-4 pl-6'>{blog.description} </p>
                <a href={blog.url} target="_blank" >
                <span className="h-8 ml-6 w-28 text-xl mt-6 pr-4 pt-0.5 mb-8 bg-[#218B1C] text-white text-center">
            More{" "}
            <svg
              fill="#ffffff"
              height="20px"
              width="16px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
              xml="preserve"
              stroke="#ffffff"
              className="relative bottom-[22px] left-[75px]"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  id="XMLID_27_"
                  d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                ></path>{" "}
              </g>
            </svg>{" "}
          </span>
                </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div> : <Loader/>
    }
    </>
  )
}

export default MainBlog

import React from 'react'
import "./Loader.css"

function Loader() {
  return (
    <div className='h-screen'>
     <div class="loader"></div> 
     </div>
  )
}

export default Loader


import React, { useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel';
import axios from 'axios';

export default function Demo() {
    let [CertificatesImages,setCertificatesImages] = useState([])
    useEffect(()=>{
      axios.get("https://agree-export-backend.onrender.com/agree/certificate/getAll")
      .then((res)=>{
        console.log(res.data.data);
        setCertificatesImages(res?.data?.data)
      }).catch((error)=>{
        console.log(error);
      })
    },[])
    const responsiveOptionscertificate = [
        {
            breakpoint: '1268px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '640px',
            numVisible: 3,
            numScroll: 3
        }
    ];

    const certificatetemplate = (certificateobject) => {
        // console.log(certificateobject)
        return (
            <div className="w-full border-1 pt-8 pb-10 surface-border border-round grid justify-center xl:pl-10 xl:pr-10"> 
                    <img src={certificateobject?.image} alt="Photo Not uploaded yet" className="w-20 h-36 shadow-xl lg:w-56 lg:h-80 md:w-44 md:h-80 xl:w-80 xl:h-80" />
            </div>
        );
    };

    return (
        <>
        <div className='flex justify-center'>
        <div className="w-4/5 pl-26 pr-26">
            <Carousel value={CertificatesImages} autoplayInterval={3000} numVisible={3} numScroll={3} responsiveOptions={responsiveOptionscertificate} itemTemplate={certificatetemplate} />
        </div>
        </div> 
        </>
    )
}
        
import React from "react";
import "./ChooseUs.css";

function ChooseUs() {
  return (
    <div className="mt-8">
      <div class="choosetitle">
        <h1>Why Choose Us</h1>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-44 pt-2 left-8"/>
        </div>
      </div>
      <div className="product-container">
        <div class="item">
          <span class="icon">
            <svg
              class="w-8 h-8 mt-0.5 text-white md:w-12 md:h-12 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 mx-auto my-auto"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
              />
            </svg>
          </span>
          <div className="chooseusinfo">
            <span>100% Client Setisfaction</span>
            <p className="text-justify">
            Aummex Exim brings years of industry knowledge to the global trade market, ensuring efficient and effective transactions.
            </p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <svg
              class="w-8 h-8 mt-0.5 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 dark:text-white mx-auto my-auto"
              version="1.1"
              id="_x32_"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              space="preserve"
              fill="#ffffff"
              stroke="#ffffff"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path
                    class="st0"
                    d="M256.495,96.434c26.632,0,48.213-21.597,48.213-48.205C304.708,21.58,283.128,0,256.495,0 c-26.65,0-48.222,21.58-48.222,48.229C208.274,74.837,229.846,96.434,256.495,96.434z"
                  ></path>{" "}
                  <path
                    class="st0"
                    d="M298.267,119.279h-42.271h-42.271c-23.362,0-48.779,25.418-48.779,48.788v162.058 c0,11.685,9.463,21.156,21.148,21.156c5.743,0,0,0,14.756,0l8.048,138.206c0,12.434,10.078,22.513,22.513,22.513 c5.244,0,14.923,0,24.585,0c9.671,0,19.35,0,24.593,0c12.434,0,22.513-10.078,22.513-22.513l8.04-138.206 c14.764,0,9.013,0,14.764,0c11.676,0,21.148-9.471,21.148-21.156V168.067C347.054,144.697,321.636,119.279,298.267,119.279z"
                  ></path>{" "}
                  <path
                    class="st0"
                    d="M104.141,149.083c23.262,0,42.105-18.85,42.105-42.104c0-23.262-18.843-42.112-42.105-42.112 c-23.27,0-42.104,18.851-42.104,42.112C62.037,130.232,80.871,149.083,104.141,149.083z"
                  ></path>{" "}
                  <path
                    class="st0"
                    d="M408.716,149.083c23.27,0,42.104-18.85,42.104-42.104c0-23.262-18.834-42.112-42.104-42.112 c-23.253,0-42.104,18.851-42.104,42.112C366.612,130.232,385.463,149.083,408.716,149.083z"
                  ></path>{" "}
                  <path
                    class="st0"
                    d="M137.257,169.024h-33.548h-36.92c-20.398,0-42.595,22.213-42.595,42.612v141.526 c0,10.212,8.264,18.476,18.468,18.476c5.018,0,0,0,12.884,0l7.024,120.704c0,10.852,8.805,19.658,19.666,19.658 c4.577,0,13.024,0,21.473,0c8.439,0,16.895,0,21.472,0c10.861,0,19.666-8.805,19.666-19.658l7.016-120.704v-6.849 c-8.98-8.856-14.606-21.082-14.606-34.664V169.024z"
                  ></path>{" "}
                  <path
                    class="st0"
                    d="M445.211,169.024h-36.928h-33.54v161.101c0,13.582-5.626,25.808-14.615,34.664v6.849l7.017,120.704 c0,10.852,8.814,19.658,19.674,19.658c4.578,0,13.025,0,21.464,0c8.456,0,16.904,0,21.481,0c10.862,0,19.659-8.805,19.659-19.658 l7.032-120.704c12.883,0,7.865,0,12.883,0c10.204,0,18.468-8.265,18.468-18.476V211.636 C487.806,191.237,465.61,169.024,445.211,169.024z"
                  ></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </span>
          <div className="chooseusinfo">
            <span>Well Managed Team</span>
            <p className="text-justify">
            We built a dream team: skilled, passionate, collaborative. Experts work in sync, leveraging experience for smooth project flow. Expect top-notch results, delivered efficiently.
            </p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <svg
              fill="#ffffff"
              class="w-8 h-8 mt-0.5 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 dark:text-white mx-auto my-auto"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 256 190"
              enable-background="new 0 0 256 190"
              space="preserve"
              stroke="#ffffff"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M48.12,27.903C48.12,13.564,59.592,2,74.023,2c14.339,0,25.903,11.564,25.903,25.903 C99.834,42.335,88.27,53.806,74.023,53.806C59.684,53.806,48.12,42.242,48.12,27.903z M191,139h-47V97c0-20.461-17.881-37-38-37H43 C20.912,60,1.99,79.14,2,98v77c-0.026,8.533,6.001,12.989,12,13c6.014,0.011,12-4.445,12-13v-75h8v88h78v-88h8l0.081,50.37 c-0.053,8.729,5.342,12.446,10.919,12.63h60C207.363,163,207.363,139,191,139z M254,77.181c0,27.246-22.073,49.319-49.319,49.319 s-49.319-22.073-49.319-49.319s22.073-49.319,49.319-49.319S254,49.936,254,77.181z M196.35,54.398c0,4.62,3.712,8.332,8.332,8.332 c4.62,0,8.332-3.751,8.332-8.332c0-4.62-3.751-8.332-8.332-8.332C200.101,46.066,196.35,49.817,196.35,54.398z M248.353,77.102 c0-23.337-18.361-42.329-41.421-43.514c11.017,1.145,19.585,10.424,19.585,21.718c0,12.083-9.793,21.836-21.836,21.836 c-12.083,0-21.836,9.793-21.836,21.836c0,11.293,8.569,20.612,19.585,21.718c0.75,0.079,1.501,0.118,2.251,0.118 C228.768,120.814,248.314,101.268,248.353,77.102z M204.681,91.633c4.601,0,8.332,3.73,8.332,8.332c0,4.601-3.73,8.332-8.332,8.332 c-4.601,0-8.332-3.73-8.332-8.332C196.35,95.364,200.08,91.633,204.681,91.633z"></path>{" "}
              </g>
            </svg>
          </span>
          <div className="chooseusinfo">
            <span> Sustainable Practices</span>
            <p className="text-justify">
            Committed to ethical and sustainable business practices, we ensure that our operations have a positive impact on both the environment and the communities we serve.
            </p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <svg
              fill="#ffffff"
              class="w-8 h-8 mt-0.5 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 dark:text-white mx-auto my-auto"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              space="preserve"
              stroke="#ffffff"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M166.435,325.511c-6.266,6.265-14.868,8.879-23.258,7.586v154.503c0,13.475,10.924,24.399,24.4,24.399 c13.475,0,24.401-10.924,24.401-24.399V299.968L166.435,325.511z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M183.813,130.853l7.939-14.439l-7.784-14.156c-0.939-1.707-0.144-3.925,1.841-4.544h-43.256 c-25.343,0-48.117,20.103-48.117,49.106v148.254c0,11.23,9.103,20.333,20.333,20.333s20.333-9.103,20.333-20.333 c0-0.92,0-147.785,0-149.277c-0.007-0.21-0.01-0.415-0.01-0.614c0-2.23,1.808-4.038,4.039-4.038s4.039,1.808,4.039,4.038v20.81 l3.764-3.764c10.551-10.55,27.627-10.551,38.179,0l3.472,3.472c-0.628-3.234-5.057-26.02-5.657-29.112 C182.537,134.632,182.851,132.601,183.813,130.853z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <circle cx="197.239" cy="42.141" r="42.141"></circle>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M226.38,227.386l-53.213-53.213c-3.945-3.945-10.342-3.946-14.289,0l-15.954,15.954v125.37 c3.899,1.899,8.52,1.113,11.565-1.932l71.891-71.891C230.325,237.729,230.325,231.332,226.38,227.386z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M211.868,136.952C211.867,136.958,211.867,136.958,211.868,136.952L211.868,136.952z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M211.87,136.94c0.001-0.003,0.002-0.009,0.003-0.015C211.872,136.932,211.871,136.936,211.87,136.94z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M211.886,136.865c0.001-0.007,0.003-0.015,0.004-0.022C211.889,136.85,211.888,136.858,211.886,136.865z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M381.103,94.571c-20.138,0-36.462,16.325-36.462,36.462c0,8.511,2.99,17.121,9.05,24.028 c-4.161-0.321-8.455,0.608-12.273,2.979l-41.18,25.569c-0.067-13.426-0.12-24.01-0.19-38.019 c-0.133-26.399-21.719-47.876-48.118-47.876h-42.855c1.977,0.618,2.781,2.83,1.84,4.543c-5.838,10.618-3.602,6.552-7.768,14.128 c4.871,8.859,3.078,5.598,7.923,14.411c0.979,1.782,1.287,3.857,0.865,5.847c-0.01,0.046-0.018,0.084-0.025,0.118 c0,0.002,0,0.003-0.001,0.004c0,0,0-0.001,0-0.002c-0.001,0.004-0.003,0.016-0.007,0.035c0,0.002-0.001,0.006-0.002,0.008 c-0.002,0.014-0.006,0.026-0.008,0.038c0-0.002,0.001-0.004,0.001-0.008c-0.002,0.009-0.004,0.021-0.007,0.032 c0-0.001,0-0.002,0.001-0.003c-0.006,0.027-0.01,0.047-0.012,0.06c0.001-0.003,0.001-0.006,0.002-0.009 c-0.002,0.009-0.004,0.02-0.007,0.03c0-0.002,0.001-0.004,0.001-0.007c-0.001,0.006-0.002,0.009-0.002,0.011 c0-0.001,0-0.001,0-0.002c-0.231,1.11-1.667,7.999-8.971,43.065l35.427,35.427c10.55,10.55,10.552,27.628,0,38.179l-35.787,35.789 c-0.002,33.133-0.026,174.765-0.026,198.192c0,13.475,10.924,24.399,24.399,24.399s24.401-10.924,24.401-24.399 c0-0.248-0.431-177.725-0.442-342.599c0-2.358,1.911-4.269,4.268-4.271c2.358-0.001,4.375,1.91,4.375,4.268 c0,0.254,0.02,0.52,0,0.794c0.368,73.228-0.038,66.754,0,74.362c0.081,15.991,17.914,25.483,31.297,17.172l72.056-44.739 c9.145-5.677,12.195-17.425,7.217-26.791c3.476,1.101,7.178,1.699,11.019,1.699c20.057,0,36.462-16.26,36.462-36.462 C417.565,110.896,401.241,94.571,381.103,94.571z"></path>{" "}
                  </g>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M211.9,136.797c0.002-0.01,0.004-0.02,0.007-0.031C211.906,136.771,211.904,136.782,211.9,136.797z"></path>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          </span>
          <div className="chooseusinfo">
            <span>Compatitive Pirce</span>
            <p className="text-justify">
            Leveraging strong relationships with suppliers and partners, we offer competitive pricing without compromising on quality.
            </p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <svg
              class="w-8 h-8 mt-0.5 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 text-white mx-auto my-auto"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.5 11.5 11 13l4-3.5M12 20a16.405 16.405 0 0 1-5.092-5.804A16.694 16.694 0 0 1 5 6.666L12 4l7 2.667a16.695 16.695 0 0 1-1.908 7.529A16.406 16.406 0 0 1 12 20Z"
              />
            </svg>
          </span>
          <div className="chooseusinfo">
            <span>Customer-Centric Approach</span>
            <p className="text-justify">
            We prioritize our client's needs by offering personalized services and tailored solutions to meet specific business requirements.
            </p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 h-8 mt-0.5 lg:w-12 lg:h-12 xl:w-20 xl:h-20 xl:mt-2 text-gray-800 dark:text-white mx-auto my-auto"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0,0,256,256"
            >
              <g
                fill="#ffffff"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{ "mix-blend-mode": "normal" }}
              >
                <g transform="scale(4,4)">
                  <path d="M45,7c-3.11354,0.0044 -5.94168,1.81485 -7.24914,4.64056c-1.30746,2.82571 -0.85671,6.15331 1.15539,8.52936l-5.62695,8.94531l-11.57227,0.77148c-0.95127,-3.47321 -4.1059,-5.88276 -7.70703,-5.88672c-4.41828,0 -8,3.58172 -8,8c0,4.41828 3.58172,8 8,8c3.60403,-0.00044 6.7628,-2.41071 7.71484,-5.88672l11.56445,0.77148l5.62695,8.94531c-2.0121,2.37604 -2.46286,5.70364 -1.15539,8.52936c1.30746,2.82571 4.13561,4.63616 7.24914,4.64056c4.41828,0 8,-3.58172 8,-8c0,-4.41828 -3.58172,-8 -8,-8c-0.83165,0.00146 -1.65798,0.1326 -2.44922,0.38867l-4.26367,-9.38867l4.26563,-9.39258c0.79041,0.2572 1.61607,0.38965 2.44727,0.39258c4.41828,0 8,-3.58172 8,-8c0,-4.41828 -3.58172,-8 -8,-8z"></path>
                </g>
              </g>
            </svg>
          </span>
          <div className="chooseusinfo">
            <span>Strong Connect</span>
            <p className="text-justify">
            Our extensive international network allows us to seamlessly navigate the complexities of global trade, delivering reliable and timely solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;

import React from "react";
import "./AboutUs.css"; // Import your CSS file for styling
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <div className="pb-24">
      <div className="text-center text-[#218B1C] pt-10 pb-5">
        <span className="text-4xl">About Us</span>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-36 left-4"/>
        </div>
      </div>
      <div className="grid grid-cols-1 relative xl:right-16 xl:grid-cols-2 xl:mx-0 xl:pt-10 ">
        <div className="h-64 grid justify-center xl:w-96 lg:pl-0 xl:pl-44">
          <div className="border-dashed rounded-xl border-2 border-[#218B1C] h-44 w-44 xl:h-80 xl:w-80 xl:relative xl:top-4 xl:left-40"></div>
          <img
            src="https://img.freepik.com/free-photo/grapes-strawberries-pineapple-kiwi-apricot-banana-whole-pineapple_23-2147968680.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1716854400&semt=ais_user"
            alt="About Us"
            className="h-44 w-44 rounded-xl relative bottom-36 left-10 xl:h-80 xl:w-80 xl:relative xl:left-56 xl:bottom-64"
          />
        </div>
        <div>
          <span className="pb-8 pl-8 pr-8 text-justify xl:pt-16 xl:pl-0 xl:pr-32">
            <p className="pb-4">
              Welcome to Aummex Exim, a leading enterprise in global trade. We
              specialize in importing and exporting goods for a variety of
              industries.{" "}
            </p>
            <p className="pb-4 text-justify">
              With extensive experience and deep industry knowledge, we navigate
              the complexities of international commerce with ease and
              efficiency. Our expansive global network ensures reliable, timely,
              and cost-effective solutions. We prioritize customer satisfaction
              through personalized services and competitive pricing without
              compromising on quality.
            </p>
            <p className="pb-4 text-justify">
              Committed to sustainability and ethical practices, we strive to
              positively impact both the environment and the communities we
              serve. Choose Aummex Exim for your import and export needs and
              experience excellence, integrity, and innovation in global trade.
            </p>
          </span>
          <Link to="/aboutus">
            <span className="h-8 w-36 ml-8 xl:ml-0 bg-[#218B1C] text-white text-center pt-1">
              Read More{" "}
              <svg
                fill="#ffffff"
                height="20px"
                width="16px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                xml="preserve"
                stroke="#ffffff"
                className="relative bottom-5 left-28 "
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    id="XMLID_27_"
                    d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                  ></path>{" "}
                </g>
              </svg>{" "}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;

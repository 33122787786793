import React, { useEffect, useState } from "react";
import "./Blog.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Loading from "../../Loader";
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

function Blogs() {
  let [Blogs,setBlogs] = useState([])
  useEffect(()=>{
    axios.get("https://agree-export-backend.onrender.com/agree/blog/getAll")
    .then((res)=>{
      res.data.data = 
      // console.log(res.data.data.slice(0,4),"blog");
      setBlogs(res.data.data.slice(0,4))
    })
  },[])
  return (
    <div className="OurBlogs-container">
      <div className="text-center text-[#218B1C] pt-4 pb-5">
        <span className="text-4xl">Blogs</span>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-20 left-4"/>
        </div>
      </div>
      <div class="OurBlogs-list">
        {Blogs.map((blog, index) => {
          return (
            <a href={blog.url} target="_blank">
            <div id={index} class="OurBlogs shadow-2xl">
              <div className="imgdiv">
              <LazyLoadImage placeholder={<Loading/>} className="OurBlogsimg object-cover" src={blog.image} alt="OurBlogs Image" />
              </div>
              <div class="OurBlogs-info">
                <h3 className="h-[80px] lg:h-[70px] md:h-[70px] xl:h-[70px] mt-4 pl-4 pr-4">
                  <b>{blog.name}</b>
                </h3>
                <span id="ourblogs-desc" className="pl-4 pr-4">
                {blog.description.slice(0, 100)}
                <span
                id="index"
                className="text-blue"
               >
                <b>...read more</b>
                </span>
                </span>
              </div>
            </div>
            </a>
          );
        })}
      </div>
      <Link
        to="/Blogs"
        className="h-8 w-28 mt-8 md:mt-10 text-xl xl:mt-8 pt-0.5 justify-self-end bg-[#218B1C] text-center text-white"
      >
        <span onClick={() => window.scrollTo(0, 0)}>
          More
          <svg
            fill="#ffffff"
            height="20px"
            width="16px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xml="preserve"
            stroke="#ffffff"
            className="relative bottom-[22px] left-[50px]"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                id="XMLID_27_"
                d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
              ></path>{" "}
            </g>
          </svg>{" "}
        </span>
      </Link>
    </div>
  );
}

export default Blogs;
import React, { Suspense } from 'react'
import Sliderloading from '../Loading/Sliderloading';
const Sliderimg = React.lazy(()=>import("../Shared/Sliderimg"));

function AutomatedSlider() {
    let sliderimg = [
        "https://th.bing.com/th/id/OIP.5U9mLqKO4wD-HL-1JhxhWwHaE8?w=640&h=427&rs=1&pid=ImgDetMain",
        "https://static.vecteezy.com/system/resources/previews/013/928/863/original/illustration-of-the-flags-of-the-g-20-countries-in-the-form-of-a-logo-with-hearts-g20-top-twenty-economies-of-the-world-financial-and-economic-international-forum-png.png",
        "https://th.bing.com/th/id/OIP.-Jz0nv6H_HFv-fpUOnRdjQAAAA?rs=1&pid=ImgDetMain",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAu5TZPARbxGNhSejmzWWkxk502JiRGaibAiZa7OEIfBQ0IBvH9LfJeZgvb9npLebQQBA&usqp=CAU",
        "https://th.bing.com/th/id/OIP.LlTo-5kCVWx2_OlyKidyFAHaHa?w=163&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        "https://th.bing.com/th/id/OIP.z5g8_Iol6zsvquI5uUfgGwHaEK?w=275&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"  
    ]
  return (
    <div class="relative font-inter antialiased">

    <main class="relative flex flex-col justify-center overflow-hidden">
    <div class="w-full inline-flex flex-nowrap pb-10 md:pb-16">
    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-4 xl:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
    {sliderimg.map((img,index)=>{
       return ( <li>
        <Suspense fallback={<Sliderloading/>}>
                <Sliderimg imgsrc={img} index={index}/>
        </Suspense>
            {/* <img src={img} className='w-16 h-16 md:w-40 md:h-40 lg:w-44 lg:h-44 xl:w-44 xl:h-44' alt="image" /> */}
        </li>)
    })}
    </ul>
    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-4 xl:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
        {sliderimg.map((img,index)=>{

        return (<li>
            <Suspense fallback={<Sliderloading/>}>
                <Sliderimg imgsrc={img} index={index}/>
            </Suspense>
            {/* <img src={img} className='w-16 h-16 md:w-40 md:h-40 lg:w-44 lg:h-44 xl:w-44 xl:h-44' alt="image" /> */}
        </li>)
    })}
    </ul>
</div>
</main>
</div>
  )
}

export default AutomatedSlider

import React from "react";
import "./About.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="pb-32">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Aummex exim</title>
        <link rel="canonical" href="https://aummexeximllp.com/AboutUs" />
      </Helmet>
      <div className="text-center text-[#218B1C] pt-10 pb-10 lg:pb-0 lg:pt-6 md:pb-0 md:pt-6 xl:pb-0">
        <span className="text-3xl">About Us</span>
        <div className="grid justify-evenly">
        <img src="./arrow.svg" className="relative w-28 left-4"/>
        </div>
      </div>
      <div className="grid grid-cols-1 md:mt-8 relative lg:right-16 lg:grid-cols-2 lg:mx-0 lg:pt-10  xl:right-16 xl:grid-cols-2 xl:mx-0 xl:pt-10 ">
        <div className="h-64 grid justify-center xl:w-96 xl:pl-40">
          <div className="border-dashed rounded-xl border-2 border-[#218B1C] h-44 w-44 lg:h-64 lg:w-64 lg:relative lg:top-4 lg:left-4 xl:h-80 xl:w-80 xl:relative xl:top-4 xl:left-40"></div>
          <LazyLoadImage
            effect="blur"
            src="https://img.freepik.com/free-photo/grapes-strawberries-pineapple-kiwi-apricot-banana-whole-pineapple_23-2147968680.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1716854400&semt=ais_user"
            alt="About Us"
            className="h-44 w-44 rounded-xl relative bottom-36 left-10 lg:h-64 lg:w-64 lg:relative lg:left-16 lg:bottom-48 xl:h-80 xl:w-80 xl:relative xl:left-56 xl:bottom-64"
          />
        </div>
        <div>
          <span className="pb-8 pl-8 pr-8 text-justify xl:pt-16 xl:pl-0 xl:pr-32">
            <p className="pb-4">
              Welcome to Aummex Exim, a leading enterprise in global trade. We
              specialize in importing and exporting goods for a variety of
              industries.  
            </p>
            <p className="pb-4 text-justify">
            With extensive experience and deep industry knowledge,
              we navigate the complexities of international commerce with ease
              and efficiency. Our expansive global network ensures reliable,
              timely, and cost-effective solutions. We prioritize customer
              satisfaction through personalized services and competitive pricing
              without compromising on quality.
            </p>
            <p className="pb-4 text-justify">
              {" "}
              Committed to sustainability and
              ethical practices, we strive to positively impact both the
              environment and the communities we serve. Choose Aummex Exim for
              your import and export needs and experience excellence, integrity,
              and innovation in global trade.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default About;
